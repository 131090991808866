<template>
  <div class="history">
    <el-card class="box-card">
      <div slot="header">
        <el-row style="display: flex; align-items: center">
          <el-col :span="4">
            <span>检测业务台账</span>
          </el-col>
          <el-col :span="11"> &nbsp; </el-col>
          <!-- 时间选择 -->
          <el-col :span="8">
            <el-date-picker
              v-model="pickerDate"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="检测开始日期"
              end-placeholder="检测完成日期"
              :editable="false"
              unlink-panels
              :picker-options="pickerOptions"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-col>
          <!-- 下载 -->

          <el-col :span="1">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              :loading="downloadLoading"
              @click="download"
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 筛选 -->
      <el-row>
        <!-- 搜索条件 -->

        <el-col :span="7">
          <el-button
            :style="index == selected ? buttonStyle : ''"
            type="primary"
            plain
            size="mini"
            v-for="(item, index) in unitNameList"
            :key="index"
            @click="chooseUnit(item, index)"
          >
            {{ item.label }}
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-select
            class="select"
            v-model="checkResult"
            placeholder="请选择是否合格"
            @change="changeCheckResult"
          >
            <el-option
              v-for="item in checkResultList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-switch
            style="display: inline-block"
            v-model="switchValue"
            active-color="#50A9FF"
            inactive-color="#50A9FF"
            active-text="不合格"
            inactive-text="合格"
            active-value="checkResultFalse"
            inactive-value="checkResultTrue"
            @change="changeSwitch"
          >
          </el-switch> -->
        </el-col>

        <el-col :span="3">
          <!-- <el-input
            placeholder="请输入设备名称"
            v-model="deviceName"
            clearable
            class="input-with-select"
          >
          </el-input> -->
          <el-select
            class="select"
            clearable
            filterable
            v-model="deviceType"
            placeholder="请选择设备类型"
          >
            <el-option
              v-for="item in deviceTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="1">&nbsp; </el-col> -->
        <el-col :span="3">
          <el-input
            placeholder="请输入报告编号"
            v-model="changeNo"
            clearable
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-input
            placeholder="请输入地区"
            v-model="addr"
            clearable
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <!-- <el-col :span="1">&nbsp; </el-col> -->
        <el-col :span="3">
          <el-date-picker
            v-model="payTime"
            align="right"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择收款日期"
            :picker-options="payTimeOptions"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="1">&nbsp; </el-col>
        <!-- 搜索 -->
        <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col>
        <!-- <el-col :span="19">&nbsp;</el-col> -->
        <!-- 下载 -->
        <!-- <el-col :span="1">
         
        </el-col> -->
      </el-row>
      <!-- 筛选 -->
      <el-table
        :data="tableData"
        border
        stripe
        style="width: 100%"
        ref="table"
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column type="index" align="center" :index="1">
          <template #header>
            <i
              class="el-icon-setting"
              style="font-size: 22px; cursor: pointer"
              @click="dialogVisible = true"
            ></i>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <!-- @click="handleClick(scope.row)" -->
            <el-button type="text">
              <a
                target="_blank"
                :href="
                  'https://www.jsjxtzjc.com/check-management' +
                  scope.row.reportFileurl
                "
                >查看报表</a
              >
              <!-- <a  target="_blank" :href="getImgUrl(scope.row.reportFileurl)">查看报表</a> -->
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, i) in columnList"
          :key="i"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
        <!-- {
          prop: 'electronicChipPhoto',
          show: false,
          label: '电子芯片'
        }, -->
        <el-table-column
          label="电子芯片"
          align="center"
          prop="electronicChipPhoto"
        >
          <template slot-scope="scope">
            <span
              v-for="(photo, idx) in splitJoin(scope.row.electronicChipPhoto)"
              :key="idx + 'photo'"
              style="margin-left: 10px"
            >
              <ImagePreview
                :src="getImgUrl(photo)"
                width="60px"
                height="60px"
              />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- dialog -->
      <el-dialog title="字段配置" :visible.sync="dialogVisible" width="30%">
        <div>
          <div>选择显示字段</div>
          <div v-for="(item, i) in column" :key="i">
            <el-checkbox v-model="item.show">{{ item.label }}</el-checkbox>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="tochangeTrue">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
import {
  checkLedger,
  checkLedgerExport,
  querySelfDisplaycol,
  updateSelfDisplaycol,
  querySysData
} from '@/api/detection'
export default {
  data() {
    return {
      listName: 'jCe',
      buttonStyle: 'background-color: #409EFF;border-radius: 4px;color:white;',
      checkResult: '1',
      checkResultList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '合格'
        },
        {
          value: '2',
          label: '不合格'
        }
      ],
      id: '',
      selected: '',
      offset: '', //当前起始位置（page-1）*limit
      page: 1, //当前页
      limit: '10', //
      total: 0,
      deviceName: '', //搜索的设备名称
      payTime: '', //付款时间
      changeNo: '', //搜索的报告编号
      addr: '', //搜索的地区
      unitName: '', //搜索的单位名称
      deviceType: null,
      unitNameList: [], //单位名称列表
      deviceTypeList: [], //设备类型下拉
      dialogVisible: false,
      downloadLoading: false,

      pictLoading: false,
      payTimeOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },

      pickerDate: [], //检测日期 时间戳
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      tableData: [],
      showColumn: [],
      column: [
        // {
        //   prop: 'electronicChipPhoto',
        //   show: false,
        //   label: '电子芯片'
        // },
        {
          show: false,
          label: '检测日期',
          prop: 'jcsj'
        },
        {
          prop: 'changeNo',
          show: false,
          label: '报告编号'
        },
        {
          prop: 'projectName',
          show: false,
          label: '工程名称'
        },
        {
          prop: 'unitUse',
          show: false,
          label: '使用单位'
        },
        {
          prop: 'unitInstall',
          show: false,
          label: '安装单位'
        },
        {
          prop: 'projectAddr',
          show: false,
          label: '地区'
        },
        {
          prop: 'unitProduction',
          show: false,
          label: '生产单位'
        },
        {
          prop: 'deviceName',
          show: false,
          label: '设备名称'
        },
        {
          prop: 'deviceModel',
          show: false,
          label: '设备型号'
        },
        {
          prop: 'factoryOutdate',
          show: false,
          label: '出厂日期'
        },
        {
          prop: 'timesTestName',
          show: false,
          label: '检验类型'
        },
        {
          prop: 'jcjl',
          show: false,
          label: '检验结论'
        },
        {
          prop: 'checkUser',
          show: false,
          label: '检验人'
        },
        {
          prop: 'reviewUser',
          show: false,
          label: '核验人'
        },

        {
          prop: 'bfhxsm',
          show: false,
          label: '问题/隐患'
        },
        {
          prop: 'bzxmbhgs',
          show: false,
          label: '保证项不合格'
        },
        {
          prop: 'ybxmbhgs',
          show: false,
          label: '一般项不合格'
        },
        {
          prop: 'payType',
          show: false,
          label: '付款方式'
        },
        {
          prop: 'actualpayment',
          show: false,
          label: '费用'
        },
        {
          prop: 'paymentFlag',
          show: false,
          label: '是否付款'
        },
        {
          prop: 'paymentTime',
          show: false,
          label: '收款日期'
        },
        {
          prop: 'type',
          show: false,
          label: '发票类型'
        },
        {
          prop: 'invoiceTitle',
          show: false,
          label: '抬头'
        },
        {
          prop: 'no',
          show: false,
          label: '开票号码'
        }
      ]
    }
  },
  computed: {
    columnList: function () {
      return this.column.filter((item) => {
        return item.show
      })
    }
  },
  methods: {
    // 默认时间
    defaultTime() {
      if (this.pickerDate.length == 0) {
        // 默认当前日期之前365天
        let sdate = new Date()
        let interval = -365 //间隔天数
        let caret = '-' //日期分隔符
        let strYear = sdate.getFullYear()
        let strDay = sdate.getDate()
        let strMonth = sdate.getMonth() + 1
        let patt1 = /^\d{4}-([0-1]?[0-9])-([0-3]?[0-9])$/ //判断输入的日期是否符合格式正则表达式
        if (!(sdate && typeof sdate == 'string' && patt1.test(sdate))) {
          sdate = new Date() //不满足日期的则使用当前年月日
        }
        interval = isNaN(parseInt(interval)) ? 0 : parseInt(interval) //若没有输入间隔，则使用当前日
        caret = caret && typeof caret == 'string' ? caret : ''
        let gdate = new Date(sdate).getTime() //获取指定年月日
        gdate = gdate + 1000 * 60 * 60 * 24 * interval //加减相差毫秒数
        let speDate = new Date(gdate) //获取指定好毫秒数时间
        let preYear = speDate.getFullYear()
        let preMonth = speDate.getMonth() + 1
        let preDay = speDate.getDate()
        preMonth = preMonth < 10 ? '0' + preMonth : preMonth
        preDay = preDay < 10 ? '0' + preDay : preDay
        let preDate = preYear + caret + preMonth + caret + preDay
        this.pickerDate = [preDate, strYear + '-' + strMonth + '-' + strDay]
      }
    },
    // 获取设备类型
    querySysDataFn() {
      querySysData().then((res) => {
        this.deviceTypeList = res.data
      })
    },
    //选择检测日期事件
    handleDateChange(pickerDate) {
      if (pickerDate == null) {
        this.pickerDate = []
      }
      this.search()
    },
    // 关闭显示列选项 改变选项
    tochangeTrue() {
      let arr = this.column.filter((item) => {
        return item.show == true
      })
      this.showColumn = []
      arr.forEach((element) => {
        this.showColumn.push(element.prop)
      })
      var data = {
        id: this.id,
        listName: this.listName,
        displayCol: this.showColumn.toString()
      }
      updateSelfDisplaycol(data).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.dialogVisible = false
        }
      })
    },
    show() {
      this.showColumn.map((item) => {
        let index = this.column.find((i) => {
          return item == i.prop
        })
        index.show = true
      })
    },
    // 字符串转数组
    splitJoin(str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    //下拉选择
    changeCheckResult(e) {
      console.log(e)
      this.checkResult = e
      this.search()
    },
    // changeSwitch() {
    //   if (this.switchValue == 'checkResultTrue') {
    //     this.checkResult = '1'
    //     // this.tableData = []
    //   } else if (this.switchValue == 'checkResultFalse') {
    //     this.checkResult = '2'
    //     // this.tableData = []
    //   }
    //   this.search()
    // },
    chooseUnit(row, index) {
      this.unitName = row.value
      this.selected = index
      // console.log(row, index, '50000000')

      this.checkLedger()
    },
    handleSizeChange(val) {
      this.limit = val
      // console.log(this.limit, this.page)
      this.checkLedger()
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.page = val
      // console.log(this.limit, this.page)
      this.checkLedger()
      // console.log(`当前页: ${val}`)
    },
    // 查询台账
    checkLedger() {
      this.pictLoading = true
      // console.log(this.payTime)
      // console.log(this.payTime, this.payTime.split('T')[0], '=======')
      var data = {
        checkResult: this.checkResult, //检验结果
        changeNo: this.changeNo, //报告编号
        addr: this.addr, //地区
        startTime: this.pickerDate[0], //开始日期
        endTime: this.pickerDate[1], //结束日期
        // deviceName: this.deviceName, //设备名称
        deviceType: this.deviceType, //设备类型
        payTime: this.payTime, //付款时间
        flagCodeJsp: this.unitName, //单位名称
        offset: (this.page - 1) * this.limit,
        limit: this.limit
      }
      checkLedger(data).then((res) => {
        console.log(res.data)
        this.tableData = res.data.total
        this.total = res.data.rows
        this.unitNameList = res.data.flagCodes
        this.pictLoading = false
      })
    },
    //查询需要展示的列
    querySelfDisplaycol() {
      var data = {
        listName: this.listName
      }
      querySelfDisplaycol(data).then((res) => {
        // console.log(res.data)
        this.id = res.data.id
        this.showColumn = res.data.displayCols
        // console.log(this.showColumn)
        this.show()
      })
    },
    // 点击搜索
    search() {
      // console.log(
      //   this.pickerDate,
      //   this.deviceName,
      //   this.changeNo,
      //   this.checkResult
      // )
      this.page = 1
      this.checkLedger()
      this.unitName = ''
    },

    // 导出报表
    download() {
      this.downloadLoading = true
      // console.log(this.deviceName)
      var data = {
        addr: this.addr, //地区
        changeNo: this.changeNo, //报告编号
        startTime: this.pickerDate ? this.pickerDate[0] || '' : '',
        endTime: this.pickerDate ? this.pickerDate[1] || '' : '',
        checkResult: this.checkResult, //检验结果
        deviceType: this.deviceType, //设备类型
        payTime: this.payTime, //付款时间
        flagCodeJsp: this.unitName //单位名称
      }
      checkLedgerExport(data).then((res) => {
        fileDownload(res, '检测业务台账.xlsx')
        // console.log(downloadResult) // 这里会输出fileDownload函数的返回值
        this.downloadLoading = false
      })
      // this.downloadLoading = false
    },
    // 图片地址拼接
    formatJson(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].filepath) {
          data[i].filepath = this.getImgUrl(data[i].filepath)
        }
      }
      return data
    }
  },
  created() {
    // this.checkLedger()
    this.querySysDataFn()

    this.querySelfDisplaycol()
  },
  mounted() {
    this.checkLedger()
  }
}
</script>

<style lang="scss" scoped>
a {
  color: cornflowerblue;
  text-decoration: none;
}
/* 控制淡入淡出效果 */
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.3s;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
// }
</style>
